
const avatarsArray = [
  'https://files.flowspeak.io/files/defalut-avatars/panda.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/tiger.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/red-panda.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/bear.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/kowla.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/vw-van.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/car-yellow.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/car-red.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/minion.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/robot.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/flower-purpble.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/lotus-pink.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/flower-pink-white.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/flower-pink-white-2.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/astronaut-1.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/cat-illustration.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/dino.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/astronaut-2.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/astronaut-3.jpg',
  'https://files.flowspeak.io/files/defalut-avatars/astronaut-4.jpg'
];


function DefaultAvatarPicker(props) {

  // Define functions
  function handleClick(event, image) {

    // Remove selected class
    const images = document.querySelectorAll(".default-avatar-container img");
    for (let i = 0; i < images.length; i++) {
      images[i].classList.remove('selected');
    }

    const element = event.target;
    element.classList.add('selected');
    props.setDefaultAvatar(image)
  }


  return (
    <div class='flex-row default-avatar-container'>
      {avatarsArray.map((image, index) =>
        <button type='button' onClick={(event) => handleClick(event, image)}>
          <img src={image} key={`avatar-image-${index}`} />
        </button>
      )}
    </div>
  );
}

export default DefaultAvatarPicker;