function ShowcaseNotFound() {

  return (
    <div class='flex-column showcase-not-found'>
      <h1>Ooops ...</h1>
      <p>Seems like we lost our way.</p>
      <p>&nbsp;</p>
      <p>
        You can head <a href='https://app.flowspeak.io' rel='noreferrer'>home</a> or contact <a href='mailto:support@flowspeak.io'>support@flowspeak.io</a>.
      </p>
      <img src='https://files.flowspeak.io/images/Error-404.png' />
    </div>
  );
}

export default ShowcaseNotFound;