import React from "react";
import { useState } from 'react';

import Modal from "../utilities/modal";
import VectorIcon from "./vectorIcon";
import AvatarImageUploadModal from "./avatarImageUploadModal";


function AvatarImageUpload() {

  // Define variables
  const [showModal, setShowModal] = useState(null);


  // Define functions
  function afterAvatarUpload() {
    setTimeout(() => {
      setShowModal(false);
    }, 1000);
  }


  // Screen components
  let uploadModalElement;
  if( showModal ) {
    const body = (
      <AvatarImageUploadModal
        afterAvatarUpload={afterAvatarUpload}
      />
    );

    uploadModalElement = (
      <Modal
        closeModal={() => setShowModal(false)}
        title={'Add Your Photo'}
        centerTitle={true}
        body={body}
        closeIcon={true}
      />
    );
  }


  return (
    <div>
      {uploadModalElement}

      <button
        class='clean-button pointer flex-row header-avatar-upload-container'
        onClick={() => setShowModal(true)}
      >
        <p>Add photo</p>
        <div class='flex-column header-avatar-upload-icon'>
          <VectorIcon icon='personAdd' size={'20px'} />
        </div>
      </button>

    </div>
  );
}

export default AvatarImageUpload;