import { useState } from "react";
import ScoreDonut from "./scoredonut";
import VectorIcon from "../vectorIcon";


function ScoreDetailBreakdown(props) {

  // Define variables
  const [showExplanation, setShowExplanation] = useState(false);
  const overall = props.overall;
  const title = props.title;
  const scoreObject = props.scoreObject || {};
  const word = scoreObject.word;
  const percent = scoreObject.percent;
  const explanation = scoreObject.explanation;
  const fontWeight = overall ? 'bold' : 'normal';

  const isIelts = props.isIelts;
  const ieltsScore = props.ieltsScore;

  const scoreToUse = isIelts ? ieltsScore :  percent;
  const wordToUse = isIelts ? ieltsScore :  word;


  // Define functions
  function handleToggleClick() {
    if( explanation ) {
      setShowExplanation(!showExplanation);
    }
  }


  // Screen componets
  let wordsElement;
  let donutElement;
  let toggleElement;

  if( overall ) {
    wordsElement = <span>{wordToUse}</span>;
    donutElement = <ScoreDonut overallScorePercent={scoreToUse} overallScoreWord={wordToUse} />;
  } else {
    wordsElement = <span>{scoreToUse}{isIelts ? '' : '%'}</span>;
    donutElement = <ScoreDonut scorePercent={scoreToUse} isIelts={isIelts} />;

    if( explanation ) {
      toggleElement = <VectorIcon icon={showExplanation ? 'chevronUp' : 'chevronDown'} size={'20px'} />;
    } else {
      toggleElement = <span class='dummy-toggle'></span>;
    }
  }


  let explanationElement;
  if( explanation && showExplanation) {
    explanationElement = <p>{explanation}</p>;
  }


  return (
    <div class='score-detail-breakdown-item'>
      <div class='flex-row'>
        <button class='flex-row' onClick={() => handleToggleClick()} style={{cursor: explanation ? 'pointer' : 'initial'}}>
          {toggleElement}
          <span style={{fontWeight: fontWeight}}>{title}</span>
        </button>
        <div class='flex-row'>
          {wordsElement}
          {donutElement}
        </div>
      </div>
      {explanationElement}
    </div>
  );
}

export default ScoreDetailBreakdown;