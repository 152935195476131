import { useState } from 'react';
import { useDispatch } from "react-redux";

import Avatar from 'react-avatar-edit'
import axios from 'axios';

import { getToken } from "./auth/tokenHelpers";
import { updatePersonalInformation } from "./auth/authApi";
import { Mixpanel } from './analytics/mixpanelHelpers';
import alertMessage from "./alertMessage";

import DefaultAvatarPicker from './defaultAvatarPicker';

import profileSlice from '../../store/profile';


function AvatarUploader(props) {

  // Define Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    dispatch(profileSlice.actions.updateField({field, value}));
  }

  // Define variables
  const [loading, setLoading] = useState(false);
  const [showAvatarPicker, setShowAvatarPicker] = useState(false);
  const [defaultAvatar, setDefaultAvatar] = useState(null);
  const [avatarButtonDisabled, setAvatarButtonDisabled] = useState(true);
  const [imageData, setImageData] = useState(null);
  const handleUploadOwnClick = props.handleUploadOwnClick;
  const afterAvatarUpload = props.afterAvatarUpload;


  // Define functions
  function handleUploadOwnClickInitial() {
    setShowAvatarPicker(!showAvatarPicker);
    handleUploadOwnClick();
  }

  function onCrop(data) {
    setImageData(data);
    setAvatarButtonDisabled(false);
  }

  function onClose() {
    setImageData(null);
    setAvatarButtonDisabled(true);
  }

  function onBeforeFileLoad(element) {
    if(element.target.files[0].size > 3000000){
      alertMessage('Please choose a file that is less than 3MB', 'error');
      element.target.value = "";
    };
  }

  function handleImageData() {
    setAvatarButtonDisabled(true);

    fetch(imageData).then(res => res.blob()).then(blob => {

      const formData = new FormData();
      formData.append(`user[avatar]`, blob, 'avatar');

      const config = {
        method: 'post',
        url: 'user/update_personal_information',
        headers: { authorization: `Bearer ${getToken()}`, "Content-Type": "multipart/form-data" },
        data : formData
      };

      // Send the data to the server
      return axios(config).then(function (response) {

        if( response.data.success ) {
          const newAvatarUrl = response.data.avatarUrl;
          updateField('avatarUrl', newAvatarUrl);
          afterAvatarUpload(newAvatarUrl);
        } else {
          alertMessage(response.data.error, 'error');
          setAvatarButtonDisabled(false);
        }

      }).catch(function (error) {
        alertMessage('Sorry, there was an error', 'error');
        setAvatarButtonDisabled(false);
      });

    });

    Mixpanel.track('Event: Profile Setup - save data', {field: 'avatar'});
  }

  function saveDefaultAvatar() {

    if( loading ) { return }

    setLoading(true);

    const payload = {
      user: { avatar_url_stored: defaultAvatar }
    };

    updatePersonalInformation(payload).then((response) => {
      if( response && response.data ) {
        if( response.data.success ) {
          updateField('avatarUrl', defaultAvatar);
          afterAvatarUpload(defaultAvatar);
        } else {
          alertMessage(response.data.error, 'error');
        }
      } else {
        alertMessage('Sorry, there was a problem. Please contact support@flowspeak.io.', 'error');
      }
    }).finally(() => {
      setLoading(false);
    });

    Mixpanel.track('Event: Choose Avatar from default list');
  }


  // Screen components
  let avatarElement;
  if( showAvatarPicker ) {
    avatarElement = (
      <div class='flex-column'>
        <DefaultAvatarPicker setDefaultAvatar={setDefaultAvatar} />

        <div style={{marginBottom: '30px'}}>
          <button
            class='profile-setup-avatar-picker-button'
            onClick={() => setShowAvatarPicker(!showAvatarPicker)}
          >or upload your own image</button>
        </div>

        <button
          class='button setup-profile-upload-button'
          onClick={() => saveDefaultAvatar()}
          disabled={!defaultAvatar}
        >Save</button>
      </div>
    );

  } else {
    avatarElement = (
      <div class='flex-column'>
        <div class='flex-column profile-input-wrapper'>
          <div class='profile-input setup-profile-upload-container'>
            <Avatar
              width={250}
              height={200}
              onCrop={onCrop}
              onClose={() => onClose()}
              onBeforeFileLoad={onBeforeFileLoad}
              src={''}
              exportAsSquare={true}
              exportMimeType={'image/jpeg'}
            />
          </div>

          <button
            class='profile-setup-avatar-picker-button'
            onClick={() => (handleUploadOwnClickInitial())}
          >or pick an Avatar from our list</button>

        </div>

        <button
          class='button setup-profile-upload-button'
          onClick={() => handleImageData()}
          disabled={avatarButtonDisabled}
        >Save</button>
      </div>
    );
  }


  return (
    <>
      {avatarElement}
    </>
  );
}

export default AvatarUploader;