import { useEffect } from 'react';

import ShowCertificate from '../utilities/stats/showCertificate';


function BadgeShowcase(props) {

  // Define variables
  const type = props.type;
  const firstName = props.firstName;
  const item = props.item;
  const itemName = item.name;
  const imageUrl = item.imageUrl;
  const issueDate = item.issueDate;


  // Component load actions
  useEffect(() => {

    // Set the page title
    const title = `${firstName} earned a ${type} | FLOW Speak`;
    const titleOg =  document.querySelector('meta[property="og:title"]');
    const titleTwitter =  document.querySelector('meta[property="twitter:title"]');
    document.title = title;
    titleOg.setAttribute('content', title);
    titleTwitter.setAttribute('content', title);

    // Set the page description
    const description = `${firstName} earned the ${itemName} ${type} on ${issueDate} from the FLOW Speak platform.`;
    const descriptionPage = document.querySelector('meta[name="description"]');
    const descriptionOg = document.querySelector('meta[property="og:description"]');
    const descriptionTwitter = document.querySelector('meta[property="twitter:description"]');
    descriptionPage.setAttribute('content', description);
    descriptionOg.setAttribute('content', description);
    descriptionTwitter.setAttribute('content', description);

    // Set the image
    const imageUrl = 'https://files.flowspeak.io/files/badges/confidence-level-200.png';
    const imageOg = document.querySelector('meta[property="og:image"]');
    const imageTwitter = document.querySelector('meta[property="twitter:image"]');
    imageOg.setAttribute('content', imageUrl);
    imageTwitter.setAttribute('content', imageUrl);

  }, []);


  // Screen components
  let imageElement;
  if( type === 'Badge' ) {
    imageElement = <img src={imageUrl} />;
  } else if( type === 'Certificate' ) {
    imageElement = <ShowCertificate certificate={item} />;
  }


  return (
    <div class='flex-column badge-showcase'>
      <p><i>{issueDate}</i></p>
      <h3>{firstName} earned a {type}</h3>
      <div class='flex-column badge-showcase-image-container'>
        {imageElement}
      </div>
    </div>
  );
}

export default BadgeShowcase;