import axios from 'axios';
import { reportError } from "./sentry";


const translations = async (payload) => {

  // Define variables
  let apiUrl = "https://microservice.flowspeak.io/api/v2/translate";
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   apiUrl = "http://localhost:8080/api/v2/translate";
  // }
  const url = encodeURI(apiUrl);
  const apiKey = "fs000000000003044092320002333";


  // Build the form data
  const formData = new FormData();

  formData.append('language', payload.language);
  formData.append('translations', JSON.stringify(payload.translations));

  const config = {
    method: 'post',
    url: url,
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "multipart/form-data",
     },
    data : formData,
  };


  return axios(config).then(function (response) {
    let data;

    if( response.status === 200 ) {
      data = response.data;
    } else {
      console.log(' ');
      console.log('translate::error', response);
      console.log(' ');
      data = {success: false, error: 'Sorry, there was an error'};
    }


    return data;
  }).catch(function (error) {
    reportError(error);
    return { success: false, error: error}
  });

}

export default translations;