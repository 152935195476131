import { Link } from "react-router-dom"
import VectorIcon from "../utilities/vectorIcon";


function CourseCardPrimary(props) {

  // Define variables
  const showAsActiveTop = props.showAsActiveTop;
  const course = props.course;
  const status = course.status;
  const premium = course.premium;
  const dateCompleted = course.dateCompleted;
  const score = course.score ? `${course.score}%` : '-';
  const weeks = course.weeks ? `${course.weeks}` : '-';;
  const learnerHasAccess = course.learnerHasAccess;


  // Screen components
  let action;
  let bannerElement;
  let completeDetailsElement;

  if( !premium || ( premium && learnerHasAccess )) {

    if( status === 'open' ) {
      action = (
        <button
          class='button course-action-button take'
          title='Tap to start course'
          onClick={() => props.handleStartCourse(course.id)}
        >Take Course</button>
      );
    } else if( status === 'active' ) {
      if( showAsActiveTop ) {

        bannerElement = <div class='flex-row course-banner active-banner'>Your Active Course</div>;

        completeDetailsElement = (
          <div class='flex-row course-activity-details'>
            <div class='flex-column course-activity-score'>
              <h4>{score}</h4>
              <small>Course Score</small>
            </div>
            <div class='flex-column course-activity-weeks'>
              <h5>{weeks}</h5>
              <p style={{marginTop: '5px', lineHeight: '1rem'}}><small >Weeks Completed</small></p>
            </div>
          </div>
        );

        action = (
          <button class='course-cancel' title='Tap to cancel course' onClick={() => props.handleCancelCourse(course.id)}>Cancel Course</button>
        );

      } else {
        action = <p class='active-label'><br />Course in progress</p>;
      }
    } else if( status === 'complete' ) {

      action = (
        <button
          class='button course-action-button retake'
          title='Tap to start course'
          onClick={() => props.handleStartCourse(course.id)}
        >Retake Course</button>
      );

      bannerElement = (
        <div class='flex-row course-banner'>
          Completed
          <VectorIcon icon='checkMarkCircle' color='#ffffff' size={'22px'} />
        </div>
      );

      completeDetailsElement = (
        <div class='flex-row course-activity-details'>
          <div class='flex-column course-activity-score'>
            <h4>{score}</h4>
            <small>Course Score</small>
          </div>
          <div class='flex-column course-activity-date'>
            <p style={{fontSize: '1.125rem'}}><strong>{dateCompleted.split(' ')[0]}</strong></p>
            <p>{dateCompleted.split(' ')[1]}</p>
          </div>
        </div>
      );

    }

  } else {
    action = (
      <div class='course-upgrade-container'>
        <p><strong>Premium Course</strong></p>
        <Link to='/packages' title='View premium membership'>Upgrade to take this course</Link>
      </div>
    );
  }


  return (
    <div class={`flex-column course-card-container ${showAsActiveTop ? 'active-course' : ''}`}>
      <div class='flex-column course-card'>
        <div class='flex-column'>
          {bannerElement}
          <h3>{course.title}</h3>
          <div class='flex-row course-details'>
            {course.level} | {course.duration} | {course.lessonCount} lessons
          </div>

          {completeDetailsElement}

          <div class='course-description'>
            <p>{course.description}</p>
          </div>
        </div>

        {action}
      </div>
    </div>
  );
}

export default CourseCardPrimary;