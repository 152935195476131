import axios from 'axios';
import { reportError } from './sentry';
import { Buffer } from 'buffer';


// function sendAIChatMessage(messages, specialMessageType = null, iconUrl = null, learnerName = null) {
function sendAIChatMessage(payload) {

  let apiUrlBase = "https://microservice.flowspeak.io/";
  const apiUrlString = "api/v2/aichat";




  // // -------------------------------------
  // // For testing and staging
  // // -------------------------------------
  // const host = window.location.host;
  // const onStaging = host.search('--flow-web.netlify.app') > 0;
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || onStaging) {

  //   // apiUrlBase = "https://nvl-api-microservice-staging.herokuapp.com/";
  //   apiUrlBase = "http://localhost:8080/";
  // }
  // // -------------------------------------



  const apiUrl = `${apiUrlBase}${apiUrlString}`;
  // const url = encodeURI(apiUrl);


  const messages = payload.messages;
  const specialMessageType = payload.specialMessageType || null;
  const iconUrl = payload.iconUrl || null;
  const learnerName = payload.learnerName || null;
  const extras = payload.extras || null;

  const formData = new FormData();
  formData.append('apiKey', "fs000000000003044092320002333");

  formData.append('messages', JSON.stringify(messages));

  if( specialMessageType ) {
    formData.append('specialMessageType', specialMessageType);
  }

  // Add the learner name
  if( learnerName ) {
    formData.append('learnerName', learnerName);
  }

  // Add the extras
  if( extras ) {
    formData.append('extras', JSON.stringify(extras));
  }


  let speakerId = 1;
  if( iconUrl ) {
    if( iconUrl.includes('woman-one') ) {
      speakerId = 3
    } else if( iconUrl.includes('woman-two') ) {
      speakerId = 5
    } else if( iconUrl.includes('woman-three') ) {
      speakerId = 6
    } else if( iconUrl.includes('man-one') ) {
      speakerId = 1
    } else if( iconUrl.includes('man-two') ) {
      speakerId = 2
    } else if( iconUrl.includes('man-three') ) {
      speakerId = 4
    } else if( iconUrl.includes('woman-four') ) {
      speakerId = 5
    } else if( iconUrl.includes('man-four') ) {
      speakerId = 2
    } else if( iconUrl.includes('woman-five') ) {
      speakerId = 6
    } else if( iconUrl.includes('man-five') ) {
      speakerId = 1
    }
  }
  formData.append('speakerId', speakerId);


  const config = {
    method: 'post',
    url: apiUrl,
    headers: { "Content-Type": "multipart/form-data" },
    data : formData,
  };

  return axios(config).then((response) => {
    if( response?.data?.success ) {

      const data = response.data.data;
      const message = validateResponse(data.message);

      let messageAudioUrl;
      const audioBufferString = data.audioBuffer;
      if( audioBufferString ) {
        const audioBufferParsed = JSON.parse(audioBufferString);

        const audioBuffer = Buffer.from(audioBufferParsed, "base64");
        const blob = new Blob([audioBuffer], {type: 'audio/wav'});

        messageAudioUrl = window.URL.createObjectURL(blob);
      }



      return { success: true, content: message, messageAudioUrl: messageAudioUrl }
    } else {
      return { success: false, error: 'Sorry, there was an error. Please contact support@flowspeak.io for help.' }
    }

  }).catch(function (error) {
    reportError(error);
  });

}


export default sendAIChatMessage;


// --

function validateResponse(message) {
  let response = message;
  let inappropriate = false;

  if (badResponseStarters.some(v => message.includes(v))) {
    response = "I'm sorry, that response is not relevant to our discussion. Please try again.";
    inappropriate = true;
  }

  // Swap "chatgpt" for 'FLOW Speak AI
  response = response.replace(/chatgpt/ig, "FLOW Speak's AI");

  return {inappropriate: inappropriate, message: response};
}

const badResponseStarters = [
  "As an AI language model",
  "an AI language model",
  "I am a language model",
  "I'm sorry, but",
  "I am sorry, but",
  "I'm sorry, I'm an AI language model",
  "I'm sorry, I cannot comply",
  "I apologize, but I cannot comply",
  "I'm sorry, I cannot fulfill that request",
  "I'm sorry, as an AI language model",
  "I'm sorry, I cannot do that. It is not appropriate or professional behavior."
]