import axios from 'axios';
import { reportError } from "./sentry";


const transcribeScoreSimple = async (audioBlob, audioFileUri, providedText, messages, extras = null) => {

  let apiUrlBase = "https://microservice.flowspeak.io/";
  const apiUrlString = "api/v2/transcribe_and_score_simple";



  // // -------------------------------------
  // // For testing and staging
  // // -------------------------------------
  // const host = window.location.host;
  // const onStaging = host.search('--flow-web.netlify.app') > 0;
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || onStaging) {

  //   // apiUrlBase = "https://nvl-api-microservice-staging.herokuapp.com/";
  //   apiUrlBase = "http://localhost:8080/";

  //   // let testing = true;
  //   // testing = false; // Change this to toggle real testing

  //   // if( testing ) {
  //   //   const dummyData = generateDummyData(audioFileUri);
  //   //   return dummyData;
  //   // }
  // }
  // // -------------------------------------


  const apiUrl = `${apiUrlBase}${apiUrlString}`;
  const url = encodeURI(apiUrl);

  const formData = new FormData();
  const apiKey = "fs000000000003044092320002333";

  formData.append('file', audioBlob, 'audio.wav');

  if( extras ) {
    formData.append('extras', JSON.stringify(extras));
  }


  // Include the last message if possible
  let lastMessage;
  if( messages ) {
    const lastMessageObject = messages[messages.length - 1];
    if( lastMessageObject && lastMessageObject.role === "assistant" ) {
      lastMessage = lastMessageObject.content;
      formData.append('lastMessage', lastMessage);
    }
  }

  const config = {
    method: 'post',
    url: url,
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "multipart/form-data",
     },
    data : formData,
  };

  return axios(config).then(function (response) {
    if( response?.data?.success ) {

      const responseData = response.data;
      responseData.audioFileUri = audioFileUri;


      return responseData;

    } else {

      let errorMessage = 'Sorry, there was an error.';

      if( response?.data?.error ) {
        errorMessage = response.data.error;
      }

      if( response?.data?.error === 'No speech is detected' ) {
        errorMessage = 'No speech recognized';
      }

      return { success: false, error: errorMessage}
    }
  }).catch(function (error) {
    reportError(error);
    return { success: false, error: error}
  });

}

export default transcribeScoreSimple;