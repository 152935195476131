import ScoreBlockRetryCount from "./scoreBlockRetryCount";
import ScoresBlockRow from "./scoresBlockRow";


function ScoresBlockBooster(props) {

  // Define variables
  const retryCount = props.retryCount;
  const scores = props.scores;
  const overallScore = scores.score;
  const pronunciationScore = scores.pronunciationScore;
  const relevanceScore = scores.relevanceScore;
  const fluencyScore = scores.fluencyScore;


  // Screen components
  let pronunciationElement;
  let fluencyElement;
  let relevanceElement;

  if( fluencyScore ) { fluencyElement = <ScoresBlockRow title={'Fluency'} score={fluencyScore} /> }
  if( relevanceScore ) { relevanceElement = <ScoresBlockRow title={'Relevance'} score={relevanceScore} /> }
  if( pronunciationScore ) { pronunciationElement = <ScoresBlockRow title={'Pronunciation'} score={pronunciationScore} /> }


  return (
    <div class='score-block-container'>
      <ScoresBlockRow title={'Overall'} score={overallScore} bold={true} overall={true} />
      {fluencyElement}
      {relevanceElement}
      {pronunciationElement}

      <ScoreBlockRetryCount retryCount={retryCount} />
    </div>
  );
}

export default ScoresBlockBooster;