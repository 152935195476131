import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  courses: [],
  coursesGrouped: [],
  courseActive: null
}

export const name = 'courses';

const coursesSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeCourses: (state, action) => {
      state.initialized = action.payload.initialized;
      state.courses = action.payload.courses;
      state.coursesGrouped = action.payload.coursesGrouped;
      state.courseActive = action.payload.courseActive;
    },
  },
});

export const { actions, reducer } = coursesSlice;
export default coursesSlice;