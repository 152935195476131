import Modal from "./modal";
import Badge from "./badge";
import BadgeSocialShareBand from "./badgeSocialShareBand";


function BadgeInfoModal(props) {

  // Define variables
  const closeModal = props.closeModal;
  const badge = props.badge;
  const issueDate = badge.issueDate;


  // Screen components
  let achievedBadge = false;
  let issueDateElement;

  if( issueDate ) {
    achievedBadge = true;
    issueDateElement = <p style={{marginTop: '-15px'}}><i>Earned {issueDate}</i></p>;
  }

  const bodyText = (
    <div class='flex-column'>
      {issueDateElement}
      <Badge
        badge={badge}
        achieved={achievedBadge}
        imageSize={250}
      />
      { achievedBadge ? <BadgeSocialShareBand shareUrl={badge.shareUrl} /> : <p>Keep up the learning to earn this badge!</p> }
    </div>
  );


  return (
    <Modal
      title={badge.name}
      body={bodyText}
      closeIcon={true}
      centerTitle={true}
      closeModal={closeModal}
    />
  );
}

export default BadgeInfoModal;