import { useState, useEffect } from "react";

import { translateBooster } from "../utilities/auth/authApi";
import PlayAudioIcon from "../utilities/playaudioicon";
import RecordResponse from "../utilities/recordresponse";
import BoosterPracticeResponse from "./boosterPracticeResponse";
import TranslateToggle from "../utilities/translate/translateToggle";
import RenderHtmlText from "../utilities/renderhtmltext";


function BoosterPractice(props) {

  // Define variables
  const [status, setStatus] = useState('waiting'); // waiting, showScore
  const [retryCount, setRetryCount] = useState(0);
  const [response, setResponse] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const id = props.id;
  const handleTranscript = props.handleTranscript;
  const avatarUrl = props.avatarUrl;
  const nextStep = props.nextStep;
  const phraseStep = props.phraseStep;
  const phraseStepIndex = phraseStep - 1;
  const booster = props.booster;
  const iconUrl = booster.iconUrl;
  const phraseRetryLimit = booster.phraseRetryLimit;

  const phraseData = booster.phrases[phraseStepIndex];
  const exampleText = phraseData.exampleText;
  const exampleAudioUrl = phraseData.exampleAudioUrl;

  const translateToggle = props.translateToggle;
  const setTranslateToggle = props.setTranslateToggle;
  const translateContent = props.translateContent;
  const setTranslateContent = props.setTranslateContent;

  const extras = props.extras;


  // Component load actions
  useEffect(() => {

    setAudioElement(null);

    setTimeout(() => {
      if( exampleAudioUrl ) {
        setAudioElement(
          <div class='booster-avatar-audio-container'>
            <PlayAudioIcon audioUrl={exampleAudioUrl} fullActive={true} />
          </div>
        );
      }
    }, 200);

  }, [exampleAudioUrl]);


  // Define functions
  function handleTranscriptInitial(transcript, audioBlobUrl, blob, responseObject) {

    // Add the blob
    responseObject.blob = blob;

    setResponse(responseObject);
    setStatus('showScore');
  }

  function retry() {
    setRetryCount(retryCount + 1);
    setStatus('waiting');
  }

  function handleNextStep() {
    response.retryCount = retryCount;
    setRetryCount(0);
    setStatus('waiting');

    handleTranscript(response);
    nextStep();
  }


  // Screen components
  let introductionText = "Let's practice using these new phrases.";

  // let audioElement;
  // if( exampleAudioUrl ) {
  //   audioElement = (
  //     <div class='booster-avatar-audio-container'>
  //       <PlayAudioIcon audioUrl={exampleAudioUrl} fullActive={true} />
  //     </div>
  //   );
  // }

  let bodyContent;
  if( status === 'waiting' ) {
    bodyContent = (
      <div class='flex-column' style={{height: '100%', justifyContent: 'center'}}>
        <RecordResponse
          handleTranscript={handleTranscriptInitial}
          providedText={exampleText}
          scorePronunciation={true}
          colorScheme={'big'}
          retryCount={retryCount}
          extras={extras}
        />
      </div>
    );
  } else {
    bodyContent = (
      <BoosterPracticeResponse
        nextStep={handleNextStep}
        retry={retry}
        response={response}
        avatarUrl={avatarUrl}
        retryLimit={phraseRetryLimit}
        retryCount={retryCount}
      />
    );
  }

  let translateElement;
  if( translateToggle && translateContent?.phrases ) {
    translateElement = (
      <div class='translate-container fade-in-on-load'>
        {translateContent.phrases[phraseStepIndex].exampleText}
      </div>
    );
  }

  // Bold the key phrase
  let exampleTextElement = <p>{exampleText}</p>;
  const phrase = phraseData.phrase;
  const phraseLower = phrase.toLowerCase();
  const formattedText = exampleText.replace(phraseLower, `<b>${phraseLower}</b>`).replace(phrase, `<b>${phrase}</b>`);

  exampleTextElement = <p><RenderHtmlText string={formattedText} /></p>;


  return (
    <section class='lesson-content'>

      <TranslateToggle
        id={id}
        apiMethod={translateBooster}
        translateToggle={translateToggle}
        setTranslateToggle={setTranslateToggle}
        translateContent={translateContent}
        setTranslateContent={setTranslateContent}
        lessonType={'Booster'}
      />

      <div class='flex-column booster-practice-container'>

        <div class='flex-row booster-practice-teacher-container'>

          <div style={{position: 'relative'}}>
            <img src={iconUrl} class='booster-avatar-medium' alt='Image of the speaking companion' />
            {audioElement}
          </div>

          <div>
            <p>{introductionText}</p>
            <p style={{marginTop: '5px'}}><i>Repeat after me:</i></p>

            <div class='booster-example-text-container'>
              {exampleTextElement}
            </div>
              {translateElement}
          </div>
        </div>

        <div class='booster-practice-speaker-container'>
          {bodyContent}
        </div>


      </div>
    </section>
  );
}

export default BoosterPractice;