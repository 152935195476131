import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import apiCall from '../utilities/auth/apiCall';
import { getPublicProfileUnauthed } from "../utilities/auth/authApi";

import LoadingBasic from '../utilities/loadingbasic';
import Flag from '../profile/flag';
import AvatarImage from '../utilities/avatarImage';
import BadgeShowcase from './badgeShowcase';
import ShowcaseNotFound from './showcaseNotFound';


function PublicProfileScreen() {

  // Define variables
  const { publicProfileId, badgeId, certificateId } = useParams();
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const firstName = profileData.firstName;
  const confidenceLevel = profileData.confidenceLevel;
  const countryCode = profileData.countryCode;
  const avatarUrl = profileData.avatarUrl;
  const badge = profileData.badge;
  const certificate = profileData.certificate;


  // Component load actions
  useEffect(() => {

    if( publicProfileId ) {

      const payload = {
        id: publicProfileId,
        badgeId: badgeId,
        certificateId: certificateId
      }

      apiCall(getPublicProfileUnauthed, payload).then((response) => {
        if( response.success ) {
          if( response.data.publicProfileDisabled ) {
            // Do nothing, let the oops message show
          } else {
            setProfileData(response.data);
          }

          setLoading(false);
        }
      });

      Mixpanel.track('Event: View public profile', {badge: badgeId, certificate: certificateId});
    }

  }, [publicProfileId]);



  // Screen components
  let loadingElement;
  if( loading ) { loadingElement = <LoadingBasic /> }

  let avatarElement;
  let flagElement;
  let firstNameElement = 'Loading ...';
  let confidenceLevelElement = 'Loading ...';

  if( !loading ) {
    if( confidenceLevel ) {
      confidenceLevelElement = `${confidenceLevel} Confidence Level`;
    }
    firstNameElement = firstName;
  }

  if( countryCode ) {
    flagElement = (
      <div style={{marginLeft: 10, marginTop: 10}}>
        <Flag countryCode={countryCode} />
      </div>
    );
  }

  if( avatarUrl && avatarUrl !== 'https://files.flowspeak.io/files/default-avatar.png' ) {
    avatarElement = <AvatarImage avatarUrl={avatarUrl} medium={true} />;
  }


  let showcaseElement;
  if( badge ) { showcaseElement = <BadgeShowcase type={'Badge'} firstName={firstName} item={badge} /> }
  if( certificate ) { showcaseElement = <BadgeShowcase type={'Certificate'} firstName={firstName} item={certificate} /> }

  if( !loading && ( (badgeId && !badge) || (certificateId && !certificate) ) ) {
    showcaseElement = <ShowcaseNotFound />;
  }



  return (
    <div class='flex-column wrapper progress-screen'>
      <div class='main-container'>

        {/* Header */}
        <div class='flex-column progress-header-wrapper'>
          <div class='flex-row progress-header-container'>
            <div style={{width: '44px', marginLeft: '30px'}}></div>
            <div class='flex-column'>
              {avatarElement}
              <div class='flex-row'>
                <h3>{firstNameElement}</h3>
                {flagElement}
              </div>
              <h5>{confidenceLevelElement}</h5>
            </div>
            <div style={{minWidth: '30px', marginRight: '30px'}} >
              &nbsp;
            </div>
          </div>
        </div>

        {/* Body */}
        <div class='main-body'>
          <div class='main-body-content'>

            {loadingElement}
            {showcaseElement}

            <div class='flex-column about-flow-speak-wrapper'>
              <div class='about-flow-speak'>
                <p>FLOW Speak is a fun and convenient way to learn how to speak English just <span class='nobr'>like a native speaker.</span></p>
                <p>&nbsp;</p>
                <p>Develop a natural-sounding speaking style by mastering everyday expressions and using <span class='nobr'>them in conversation scenarios.</span></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicProfileScreen;