import AvatarUploader from './avatarUploader';


function AvatarImageUploadModal(props) {

  function handleUploadOwnClick() {
    // Don't need to do anything in this instance
  }


  return (
    <>
      <AvatarUploader
        handleUploadOwnClick={handleUploadOwnClick}
        afterAvatarUpload={props.afterAvatarUpload}
      />
    </>
  );
}

export default AvatarImageUploadModal;