import ScoreBlockRetryCount from "./scoreBlockRetryCount";
import ScoresBlockRow from "./scoresBlockRow";


function ScoresBlockSpeakingIelts(props) {

  // Define variables
  const retryCount = props.retryCount;
  const scores = props.scores;
  const overallScore = scores.ieltsOverallScore;
  const fluencyScore = scores.ieltsFluencyAndCoherence;
  const relevanceScore = scores.ieltsRelevance;
  const vocabularyScore = scores.ieltsVocabulary;
  const grammarScore = scores.ieltsGrammar;
  const pronunciationScore = scores.ieltsPronunciation;


  // Screen components
  let relevanceElement;
  if( relevanceScore && relevanceScore != 0.0 ) {
    relevanceElement = <ScoresBlockRow title={'Relevance'} score={relevanceScore} isIelts={true} />;
  }


  return (
    <div class='score-block-container'>
      <ScoresBlockRow title={'Overall'} score={overallScore} bold={true} isIelts={true} />
      <ScoresBlockRow title={'Fluency and Coherence'} score={fluencyScore} isIelts={true} />
      {relevanceElement}
      <ScoresBlockRow title={'Lexical Resource'} score={vocabularyScore} isIelts={true} />
      <ScoresBlockRow title={'Grammatical Range & Accuracy'} score={grammarScore} isIelts={true} />
      <ScoresBlockRow title={'Pronunciation'} score={pronunciationScore} isIelts={true} />

      <ScoreBlockRetryCount retryCount={retryCount} />
    </div>
  );
}

export default ScoresBlockSpeakingIelts;