import { createSlice } from '@reduxjs/toolkit';
import { reportError } from "../functions/sentry";

const initialState = {
  isInitialized: false,
  id: null,
  isSaved: false,
  isIelts: false,
  lessonData: {
    learnerHasAccessToLesson: true,
    iconUrl: 'https://files.flowspeak.io/files/default-avatar.png',
    title: 'Loading ...',
    introductionText: 'Loading ...',
    phrases: [],
    everyMessageAddonResponderText: '',
    learnerSpeakingTurns: 3,
    relatedLessons: []
  },
  messages: [],
  responses: [],
  exerciseId: null
}

export const name = 'booster';

const boosterSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    setBooster: (state, action) => {
      state.isInitialized = true;
      state.id = action.payload.id;
      state.isSaved = action.payload.isSaved;
      state.isIelts = action.payload.isIelts;
      state.lessonData = action.payload.lessonData;
      state.messages = action.payload.messages;
    },

    addMessageBooster: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },

    addResponseBooster: (state, action) => {
      // state.responses = [...state.responses, action.payload];
      // Try to prevent an error that I haven't been able to track down
      if( action?.payload?.success ) {
        try {
          state.responses = [...state.responses, action.payload];
        } catch {
          console.log(' ');
          console.log('action', action);
          console.log(' ');
          reportError(action);
        }
      }
    },

    setIsSavedBooster: (state, action) => {
      state.isSaved = action.payload;
    },

    setExerciseIdBooster: (state, action) => {
      state.exerciseId = action.payload;
    },

    resetBooster: (state, action) => {
      state.id = initialState.id;
      state.lessonData = initialState.lessonData;
      state.isSaved = initialState.isSaved;
      state.isInitialized = initialState.isInitialized;
      state.messages = initialState.messages;
      state.responses = initialState.response;
    },

  },
});

export const { actions, reducer } = boosterSlice;
export default boosterSlice;