import CourseCardPrimary from "./courseCardPrimary";


function CourseGroup(props) {

  // Define variables
  const handleStartCourse = props.handleStartCourse;
  const handleCancelCourse = props.handleCancelCourse;
  const group = props.group;
  const title = group.title;
  const courses = group.courses;


  return (
    <div class='course-group-container'>
      <h2 class='course-group-h2'>{title}</h2>
      <div class='course-group-h2-block'></div>
      <div class='flex-row course-list-wrapper'>
        {courses.map((course, index) =>
          <CourseCardPrimary
            course={course}
            handleStartCourse={handleStartCourse}
            key={`course-${index}`}
            handleCancelCourse={handleCancelCourse}
          />
        )}
      </div>
    </div>
  );
}

export default CourseGroup;