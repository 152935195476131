import { useState } from "react";

import AssessmentInstruction from "../helpers/assessmentInstruction";
import ConversationThread from "./conversationThread";


function AssessmentConversation(props) {

  // Define variables
  const [step, setStep] = useState(0);
  const config = props.config;
  const handleSteps = props.handleSteps;
  const instructions = props.instructions;
  const conversationInitialPrompt = config.conversationInitialPrompt;
  const everyMessageAddon = conversationInitialPrompt.everyMessageAddon;
  const secondaryMessageAddon = conversationInitialPrompt.secondaryMessageAddon;
  const speakerIconUrl = conversationInitialPrompt.iconUrl
  const messages = [{role: "user", content: conversationInitialPrompt.text, iconUrl: speakerIconUrl, display: false}];


  // Define functions
  function logProgress(data) {
    if( data.step ) {
      setStep(data.step);
    }

    handleSteps(data);
  }


  // Screen components
  let bodyContent;

  if( step === 0 ) {
    bodyContent = (
      <>
        <h1>Conversation</h1>
        <br />
        <AssessmentInstruction logProgress={logProgress} instructions={instructions} nextStep={1} passageStep={1} />
      </>
    );
  } else if( step === 1 ) {
    bodyContent = (
      <ConversationThread
        config={config}
        logProgress={logProgress}
        instructionReview={instructions.review}
        instructionComplete={instructions.complete}
        messages={messages}
        speakerIconUrl={speakerIconUrl}
        everyMessageAddon={everyMessageAddon}
        secondaryMessageAddon={secondaryMessageAddon}
      />
    );
  }


  return (
    <>
      {bodyContent}
    </>
  );
}

export default AssessmentConversation;