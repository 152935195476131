import React from "react";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';

import '../../styles/post.css';

import NewPost from "./newpost";
import PostRouter from "./postrouter";
import PostIndexIntroCard from "./postindexintrocard";

import { getPosts } from "../utilities/auth/authApi";
import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import LoadingBasic from "../utilities/loadingbasic";

import postsSlice from "../../store/posts";

import fadeInMainBody from "../../functions/fadeinmainbody";


function Community () {

  // Initalize redux
  const dispatch = useDispatch();

  function addNewPost(post) {
    // dispatch({ type: 'addNewPost', payload: post});
    dispatch(postsSlice.actions.addNewPost(post));
  }

  function updateLikeCount(updateObject) {
    // dispatch({ type: 'updateLikeCount', payload: {updateObject: updateObject} });
    dispatch(postsSlice.actions.updateLikeCount({updateObject: updateObject}));
  }

  function setCurrentScrollPosition(value) {
    // dispatch({ type: 'setCurrentScrollPosition', payload: {currentScrollPosition: value} });
    dispatch(postsSlice.actions.setCurrentScrollPosition({currentScrollPosition: value}));
  }


  // Set state
  const [loading, setLoading] = useState(<LoadingBasic />);

  // See if posts data has already been loaded
  const postsLoaded = useSelector((state) => state.posts.initialized);
  const posts = useSelector((state) => state.posts.posts);
  const currentScrollPosition = useSelector((state) => state.posts.currentScrollPosition);


  useEffect(() => {

    fadeInMainBody(currentScrollPosition);

    if( !postsLoaded ) {
      getPosts().then((response) => {
        if( response.data ) {
          response.data.initialized = true;

          // Temp for now
          let postData = response.data;

          const today = Date.now();
          const futureDate = new Date('2024-12-14');

          if( futureDate >= today ){
            const postsList = postData.posts;
            const stickyIndex = postsList.findIndex(item => item.id === 40429);
            if( stickyIndex > 0 ) {
              postsList.unshift(postsList.splice(stickyIndex, 1)[0]);
            }
          }

          // Update the redux store
          dispatch(postsSlice.actions.initializePosts(postData));

          // removeLoadingState(posts);
          setLoading(false);

        }
      });
    } else {
      // removeLoadingState(posts);
      setLoading(false);
    }

  // });
  }, [postsLoaded]);


  window.onscroll = function() {
    if ((window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight) {
      if( window.location.pathname === '/community' ) { loadMorePosts() }
    }
  };


  function loadMorePosts() {
    if( posts.length >= showPostCount ) {
      // Load more posts
      showPostCount = showPostCount + showPostCountInitial;

      let postcount = 0;

      // TO DO - skip elmeent we already showed
      posts.forEach((post, index) => {
        if( postcount <= showPostCount ) {
          const postId = post.id;
          const thisPost = document.getElementById('post-wrapper-' + postId);
          if( thisPost ) { thisPost.classList.remove('is-hidden') }
        }

        postcount += 1;
      });
    }
  }


  function trackScrollPosition(){
    const currentScrollPosition = window.pageYOffset;
    setCurrentScrollPosition(currentScrollPosition);
  }


  function handleNewPostSuccess(post, form, leaveActive) {

    // Add the new post to the list
    addNewPost(post);

    // Tell the leanrer the post was added
    toast.success('Post created!');

    // Clear the form
    const editor = form.querySelector('#text-editor-basic');
    const textarea = form.querySelector('textarea');

    // Clear the audio
    const removeAudio = form.querySelector('.remove-audio');
    if( removeAudio ) { removeAudio.click() }

    if( editor ) {
      // Clear the content and trigger a change event so the
      // state picks it up
      editor.innerHTML = '';
      editor.focus();
      editor.blur();
    }

    if( textarea ) { textarea.value = '' }

    // Set the form style back to inactive
    if( !leaveActive ) {
      setTimeout(() => {
        form.classList.add('inactive');
      }, 300);
    }

  }


  function handleLikeChange(updateObject) {
    updateLikeCount(updateObject);
  }


  return (

    <div class='wrapper gray-background'>
      <Toaster />
      <Nav />

      <div class='main-body invisible community'>
        <HeaderMain title="Community" />
        <div class='main-body-content-flex-wrapper' style={{marginTop: '30px'}}>
          <div class='main-body-content community'>

            <div class='left-column'>

              <div class='dynamic-elements'>
                <NewPost handleNewPostSuccess={handleNewPostSuccess} />
                <PostIndexIntroCard />
              </div>

              {posts.map((post, index) =>
                <PostRouter
                  handleLikeChange={handleLikeChange}
                  // infiniteScroll={true}
                  index={index}
                  key={index}
                  post={post}
                  showPostCountInitial={showPostCountInitial}
                  trackScrollPosition={trackScrollPosition}
                />
              )}

              {loading}
            </div>

            <div class='right-column'>
              <NewPost handleNewPostSuccess={handleNewPostSuccess} startActive={true} />
              <div>
                <h4>Listen, Speak, Ask</h4>
                <p>This is your Community. Learning together is better. Give and receive feedback so we all improve.</p>
                <ul>
                  <li>Create a post to ask a question</li>
                  <li>Leave a comment to help others improve</li>
                  <li>Learn from others</li>
                </ul>



              </div>

            </div>

          </div>
        </div>
      </div>
      <BottomNav />
    </div>

  );
}

// Keep this outside of state to prevent a re-render
let showPostCountInitial = 5;
let showPostCount = showPostCountInitial;

export default Community;