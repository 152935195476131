import ScoresBlockRow from "./scoresBlockRow";


function ScoresBlockAiTalk(props) {

  // Define variables
  const scores = props.scores;
  const overallScore = scores.score;
  const fluencyScore = scores.fluencyScore;
  const relevanceScore = scores.relevanceScore;


  // Screen components
  let relevanceElement;
  if( relevanceScore ) {
    relevanceElement = <ScoresBlockRow title={'Relevance'} score={relevanceScore} />;
  }


  return (
    <div class='score-block-container'>
      <ScoresBlockRow title={'Overall'} score={overallScore} bold={true} overall={true} />
       <ScoresBlockRow title={'Fluency'} score={fluencyScore} />
      {relevanceElement}
    </div>
  );
}

export default ScoresBlockAiTalk;