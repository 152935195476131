import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import '../../styles/dashboard.css';
import '../../styles/cardstyles.css';

// import alertMessage from "../utilities/alertMessage";
import { reportError } from "../../functions/sentry";
import * as api from "../utilities/auth/authApi";
import apiCall from '../utilities/auth/apiCall';
import { getDashboardData } from "../utilities/auth/authApi";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import LoadingBasic from "../utilities/loadingbasic";

import Nav from "../utilities/nav";
import BottomNav from "../utilities/bottomnav";

import IntroTour from "./introtour";
import NewBadgeModal from "./newBadgeModal";

import fadeInMainBody from "../../functions/fadeinmainbody";
import AppLinksCard from "./applinkscard";
import InfoCard from "./infocard";
import OrgShare from "./orgShare";
import OrgLogoElement from "../utilities/orgLogoElement";
import WeekLists from "./weekLists";

import StepRouter from "./steps/stepRouter";
import PathwayProgressReport from '../utilities/pathways/pathwayProgressReport';
import DashboardProfileSetup from "../utilities/dashboardProfileSetup";

import dashboardSlice from "../../store/dashboard";
import profileSlice from "../../store/profile";


function Dashboard() {

  // Dispatch functions
  const dispatch = useDispatch();

  function initializeDashboardData(payload) {
    // dispatch({ type: 'initializeDashboardData', payload: payload });
    dispatch(dashboardSlice.actions.initializeDashboardData(payload));
  }


  // Define variables
  const [introTour, setIntroTour] = useState(null);
  const [badgeModal, setBadgeModal] = useState(null);
  const [orgShareElement, setOrgShareElement] = useState(null);

  const dashboardData = useSelector((state) => state.dashboardData);
  const profileSetup = useSelector((state) => state.dashboardData?.headerData?.profileSetup);
  const initialized = dashboardData.initialized;
  // const headerData = dashboardData.headerData;
  const lessonGroups = dashboardData.lessonGroups;
  const stepData = dashboardData.stepData;


  // See if profile & this week data has already been loaded
  const profileDataLoaded = useSelector((state) => state.profile.initialized);
  const infoCard = useSelector((state) => state.profile.infoCard);
  const orgLogo = useSelector((state) => state.profile.orgLogo);


  // Component load actions
  useEffect(() => {

    fadeInMainBody();

    if( !profileDataLoaded ) {

      api.getProfile().then((response) => {
        if( response.data ) {
          response.data.initialized = true;

          // Update the redux store
          // dispatch({ type: 'initializeProfile', payload: response.data });
          dispatch(profileSlice.actions.initializeProfile(response.data));

          // Set the local storage values
          // TO DO - move this to redux
          if( response.data.nativeLanguage ) {
            localStorage.setItem("nativeLanguageCode", response.data.nativeLanguage);
          }

          if( response.data.autoplayAudio ) {
            localStorage.setItem("autoplayAudio", response.data.autoplayAudio);
          }

          // Org share
          if( response.data.askToShareWithOrg ) {
            setOrgShareElement(<OrgShare />);
          }

          Mixpanel.identify(response.data.id);

          if( window.hj && process.env.NODE_ENV === 'production' ) {
            window.hj('identify', response.data.id, {
              first_name: response.data.firstName
            });
          }

        }
      }).catch((error) => {
        reportError(error);
      }).finally(() => {
        if( localStorage.getItem("showIntroTour") ) {
          setIntroTour(<IntroTour />);

          // Onboarding tracking
          Mixpanel.track('Onboarding: Finished On Dashboard');

          localStorage.removeItem("showIntroTour", true);
        }

      });

    }

    if( !initialized ) {
      apiCall(getDashboardData, {}).then((response) => {
        if( response.success ) {
          initializeDashboardData(response.data);
        }
      });
    }

    // Get the updated score every time we come to the dashboard
    // that way the score is alwasy up to date
    api.getUpdatedScore().then((response) => {

      if( response.data ) {

        // Update the redux store
        // dispatch({ type: 'setScore', payload: response.data });
        dispatch(profileSlice.actions.setScore(response.data));

        // Update challenge data
        // dispatch({ type: 'updateField', payload: {field: 'challenge', value: response.data.challengeData} });
        dispatch(profileSlice.actions.updateField({field: 'challenge', value: response.data.challengeData}));

        // See if there is a new badge
        if( response.data.newBadge ) {
          setBadgeModal(
            <NewBadgeModal
              badge={response.data.newBadge}
              setBadgeModal={setBadgeModal}
            />
          );

          Mixpanel.track('Event: New Badge - show modal');
        }

      }
    }).catch((error) => {
      reportError(error);
    });

  }, [dispatch, initialized]);


  // Define functions
  // function loadMoreLessons() {
  //   api.getMoreLessons().then((response) => {
  //     if( response?.data?.success ) {
  //       dispatch({ type: 'addMoreLessons', payload: response.data.lessons });
  //     } else {
  //       alertMessage('Sorry, there was an error. Please contact support@flowspeak.io for assistance.', 'error');
  //     }
  //   }).catch(function (error) {
  //     reportError(error);
  //   });
  // }


  // Screen components
    let lessonDisplay;
  if( initialized && lessonGroups?.length > 0 ) {
    lessonDisplay = <WeekLists lessonGroups={lessonGroups} initialized={initialized} />;
  }

  let mainBodyClass = 'main-body-content dashboard';
  // if( activeCourse ) { mainBodyClass += ' course' }

  let infoCardElement;
  if( infoCard ) {
    infoCardElement = <InfoCard data={infoCard} />;
  }

  let orgLogoElement;
  if( orgLogo ) {
    orgLogoElement = <OrgLogoElement orgLogo={orgLogo} />;
  }

  let stepElement;
  if( stepData?.step || stepData?.step === 0 ) {
    stepElement = <StepRouter stepData={stepData}  />
  }

  let loadingElement;
  if( !initialized ) {
    loadingElement = (
      <div class='week-list-container'>
        <LoadingBasic />
      </div>
    );
  }

  let progressReportElement;
  if( !stepData?.step && dashboardData?.headerData?.pathwayData?.level > 0 ) {
    progressReportElement = <PathwayProgressReport />;
  }

  let profileSetupElement;
  if( profileSetup ) {
    profileSetupElement = <DashboardProfileSetup profileSetup={profileSetup} />;
    if( !orgLogo ) { orgLogoElement = <span>&nbsp;</span> }
  }


  return (
    <div class='flex-column wrapper'>

      {badgeModal}
      {introTour}

      <Nav />

      <div class='main-body dashboard invisible'>

        <div class={mainBodyClass}>
          <div class='flex-row' style={{marginTop: '15px', marginBottom: '30px'}}>
            {orgLogoElement}
            {profileSetupElement}
          </div>
          {infoCardElement}
          {orgShareElement}
          {lessonDisplay}
          {loadingElement}
          {stepElement}
          {progressReportElement}
        </div>

        <AppLinksCard />

        <div class='dashboad-help-card'>
          <strong>Need help? &nbsp;</strong>
          <span>Have a suggestion? <br /></span>
          <span>
            Email us at <a href='mailto:achieve@flowspeak.io'>achieve@flowspeak.io</a>.
          </span>
        </div>

      </div>

      <BottomNav />

    </div>
  );
}

export default Dashboard;