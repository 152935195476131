import { reportError } from './sentry';
import { logExerciseComplete } from '../components/utilities/auth/authApi';

const sendExerciseData = async (payload) => {

  const exerciseId = payload.exerciseId;
  const lessonType = payload.lessonType;
  const lessonId = payload.lessonId;
  const responsesToSave = payload.responsesToSave || [];

  const formData = new FormData();
  formData.append('exercise_id', exerciseId);
  formData.append('lesson_type', lessonType);
  formData.append('lesson_id', lessonId);


  // Loop the responses
  responsesToSave.forEach((response, index) => {

    const ieltsScores = response.ieltsScores;

    const text = response.text ? response.text : response.transcript;
    formData.append(`responses[][text]`, text);

    formData.append(`responses[][retry_count]`, (response.retryCount ? response.retryCount : 0));

    if( response.moduleContentId ) {
      formData.append(`responses[][module_content_id]`, response.moduleContentId);
    }

    if( response.overall ) {
      formData.append(`responses[][score]`, response.overall.percent);
    }

    if( response.pronunciation ) {
      formData.append(`responses[][pronunciation_score]`, response.pronunciation.percent);
    }

    if( response.cadence ) {
      formData.append(`responses[][cadence_score]`, response.cadence.percent);
    }

    if( response.vocabulary ) {
      formData.append(`responses[][vocabulary_score]`, response.vocabulary.percent);
    }

    if( response.fluency ) {
      formData.append(`responses[][fluency_score]`, response.fluency.percent);
    }

    if( response.grammar ) {
      formData.append(`responses[][grammar_score]`, response.grammar.percent);
    }

    if( response.relevance ) {
      formData.append(`responses[][relevance_score]`, response.relevance.percent);
    }

    if( ieltsScores ) {
      formData.append(`responses[][ielts_overall_score]`, ieltsScores.overall);
      formData.append(`responses[][ielts_fluency_and_coherence]`, ieltsScores.fluencyAndCoherence);
      formData.append(`responses[][ielts_relevance]`, ieltsScores.relevance);
      formData.append(`responses[][ielts_grammar]`, ieltsScores.grammar);
      formData.append(`responses[][ielts_pronunciation]`, ieltsScores.pronunciation);
      formData.append(`responses[][ielts_vocabulary]`, ieltsScores.vocabulary);
    }

    let attachedAudio = false;
    if( response.blob instanceof Blob ) {
      // Need a uniquie filename for exercise files
      const fileName = `response-exercise-${exerciseId}-${index}.wav`;
      formData.append(`responses[][audio]`, response.blob, fileName);
      attachedAudio = true;
    }

    // if( responseData.audioFileUri && !attachedAudio ) {
    //   formData.append(`responses[][audio]`, {
    //     name: fileName,
    //     type: 'audio/mpeg',
    //     uri: responseData.audioFileUri
    //   });
    // }

  });


  return logExerciseComplete(formData).then((response) => {
    if( response?.data?.success ) {
      return {
        success: true,
        data: response.data.data
      }
    } else {
      reportError(response);
      return {error: 'Sorry, thre was an error. Please contact support@flowspeak.io for help.'}
    }
  }).catch(function (error) {
    reportError(error);
    return error;
  });

}

export default sendExerciseData;