import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import alertMessage from "../utilities/alertMessage";
import { cancelAccount } from "../utilities/auth/authApi";

import profileSlice from '../../store/profile';


function CancelAccount() {

  // Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    dispatch(profileSlice.actions.updateField({field, value}));
  }


  // Define variables
  const scheduledForCancel = useSelector((state) => state.profile.scheduledForCancel);


  // Define functions
  function confirmCancelAccount() {
    if (window.confirm('Are you sure? This will remove all access to your FLOW Speak account.')) {
      callCancelAccount();
    };
  }

  function callCancelAccount() {
    cancelAccount({scheduled_for_cancel: true, cancel_reason: 'web'}).then((response) => {
      if( response && response.data && response.data.success ) {
        // Update redux
        updateField('scheduledForCancel', true);
      } else {
        alertMessage('Sorry, there was an error. Please contact support@flowspeak.io for further assistance.', 'error')
      }
    }).catch((error) => {
      reportError(error, 'My info - handle cancel account');
    });
  }


  // Screen components
  let bodyElement = (
    <div class='profile-extras'>
      <Link to='#' onClick={() => confirmCancelAccount()}>Cancel your account</Link>
      <p>&nbsp;</p>
    </div>
  );

  if( scheduledForCancel ) {
    bodyElement = (
      <div class='profile-extras'>
        <p><i>You account is scheduled for to be cancelled within 3 days.</i></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    );
  }


  return (
    <>{bodyElement}</>
  );
}

export default CancelAccount;