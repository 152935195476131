function OrgLogoElement(props) {

  // Define variables
  const orgLogo = props.orgLogo
  let height = '50px';
  let width = '135px';

  // Hack
  if( orgLogo.includes('simpul')) {
    height = '70px';
    width = '189px';
  }


  return (
    <div
      class=''
      style={{
        width: width,
        height: height,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${orgLogo})`
      }}
    ></div>
  );
}

export default OrgLogoElement;