import IssueDateElement from "./issueDateElement";
import BadgeSocialShareBand from "../badgeSocialShareBand";
import alertMessage from '../alertMessage';


function ShowCertificate(props) {

  // Define variables
  const certificate = props.certificate;
  const isThisLearner = props.isThisLearner;


  // Define functions
  function copyLink() {
    navigator.clipboard.writeText(certificate.shareUrl);
    alertMessage('Share Link Copied');
  }


  // Screen components
  let downloadElement;
  let shareElement;

  if( isThisLearner ) {
    downloadElement = <a href={certificate.downloadUrl} class='stats-certificate-download-link' target="_blank" rel="nofollow">Download Certificate</a>;

    shareElement = (
      <div class='stats-certificate-share-band'>
        <BadgeSocialShareBand shareUrl={certificate.shareUrl} title={'I earned a FLOW Speak Certificate'} />
      </div>
    );
  }


  return (
    <div class='stats-certificate-container'>
      <div class='flex-column stats-certificate-detail'>
        <span class='icon icon-certificate'></span>
        <h5>{certificate.name}</h5>
        <p style={{marginTop: '10px'}}>{certificate.description}</p>
      </div>
      {downloadElement}
      {shareElement}
      <IssueDateElement issueDate={certificate.issueDate} />
    </div>
  );
}

export default ShowCertificate;