import ScoreDonut from "../../utilities/learning_module/scoredonut";


function ScoresBlockRow(props) {

  // Define variables
  const title = props.title;
  const score = props.score;
  const isIelts = props.isIelts;
  const fontWeight = props.bold ? 'bold' : 'normal';


  return (
    <div class='flex-row score-block-row'>
      <p style={{fontWeight: fontWeight}}>{title}</p>
      <p class='flex-row'>
        {score}{isIelts ? '' : '%'}
        <ScoreDonut scorePercent={score} isIelts={isIelts} />
      </p>
    </div>
  );
}

export default ScoresBlockRow;