import Modal from "../utilities/modal";
import Badge from "../utilities/badge";
import BadgeSocialShareBand from "../utilities/badgeSocialShareBand";


function NewBadgeModal(props) {

  // Define variables
  const badge = props.badge;
  const setBadgeModal = props.setBadgeModal;
  const title = 'You Earned a Badge! 🎉';
  // const description = badge.description ? <p>{badge.description}</p>: null;
  const message = messages[badge.scoreLevel];

  const body = (
    <div class='flex-column'>
      <h4>{badge.name}</h4>
      {/* {description} */}
      <p class='has-text-centered'>{message}</p>
      <Badge
        badge={badge}
        achieved={badge}
        imageSize={250}
      />
      <BadgeSocialShareBand shareUrl={badge.shareUrl} />
    </div>
  )


  // Define functions
  function closeModal() {
    setBadgeModal(null);
  }

  return (
    <div id='badge-modal-wrapper'>
      <Modal
        windowClass={''}
        title={title}
        centerTitle={true}
        body={body}
        closeIcon={true}
        closeModal={closeModal}
      />
    </div>
  );
}

export default NewBadgeModal;


// ----

const messages = {
  200: "Great job! 50% of members reach this level. Stay active to reach higher! 📈",
  300: "You're awesome! Only 40% of members reach this level. Keep going with the FLOW!",
  400: "You're amazing! Just 30% of members reach this level. Keep going up. We believe in you!",
  500: "You're crushing it! Only 20% of members reach this level. Keep aiming higher!",
  600: "You're a superstar! Stay active to keep your confidence up! 📈",
  700: "Congratulations! Only 10% of members reach this level. Keep going higher!",
  800: "You're fantastic! Keep going to the top!",
  900: "You're an elite learner! Only 5% of members reach this level. You're almost at the top. You got this!",
  1000: "You're the BEST! Less than 1% of members reach the 1000 confidence level. Stay active to maintain your speaking skills and be a leader to other members.",
}