import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import alertMessage from "./alertMessage";


function BadgeSocialShareBand(props) {

  // Define variables
  const shareUrl = props.shareUrl;
  const title = props.title || 'I earned a FLOW Speak Badge';


  // Define functions
  function copyLink() {
    navigator.clipboard.writeText(shareUrl);
    alertMessage('Share Link Copied');
  }


  // Screen components
  let copyElement;
  if( navigator ) {
    copyElement = (
      <button
        class='clean-button copy-share-link-button'
        onClick={() => copyLink()}
      >
        or copy your share link
      </button>
    );
  }


  return (
    <div class='mt-4'>

      <p class='has-text-centered' style={{marginBottom: '10px'}}>Share Your Achievement</p>

      <div class='social-share-container'>

        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>

        <FacebookShareButton
          url={shareUrl}
          quote={title}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={shareUrl}
          title={title}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>

        <LineShareButton
          url={shareUrl}
          title={title}
        >
          <LineIcon size={32} round />
        </LineShareButton>

        </div>

        {copyElement}
    </div>
  );
}

export default BadgeSocialShareBand;