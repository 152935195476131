import ScoreBlockRetryCount from "./scoreBlockRetryCount";
import ScoresBlockRow from "./scoresBlockRow";


function ScoresBlockSpeaking(props) {

  // Define variables
  const retryCount = props.retryCount;
  const scores = props.scores;
  const overallScore = scores.score;
  const fluencyScore = scores.fluencyScore;
  const relevanceScore = scores.relevanceScore;
  const grammarScore = scores.grammarScore;
  const pronunciationScore = scores.pronunciationScore;
  const vocabularyScore = scores.vocabularyScore;
  const speedScore = scores.cadenceScore;


  // Screen components
  let fluencyElement;
  let relevanceElement;
  let grammarElement;
  let pronunciationElement;
  let vocabularyElement;
  let speedElement;

  if( fluencyScore ) { fluencyElement = <ScoresBlockRow title={'Fluency'} score={fluencyScore} /> }
  if( relevanceScore ) { relevanceElement = <ScoresBlockRow title={'Relevance'} score={relevanceScore} /> }
  if( grammarScore ) { grammarElement = <ScoresBlockRow title={'Grammar'} score={grammarScore} /> }
  if( pronunciationScore ) { pronunciationElement = <ScoresBlockRow title={'Pronunciation'} score={pronunciationScore} /> }
  if( vocabularyScore ) { vocabularyElement = <ScoresBlockRow title={'Vocabulary'} score={vocabularyScore} /> }
  if( speedScore ) { speedElement = <ScoresBlockRow title={'Speed'} score={speedScore} /> }


  return (
    <div class='score-block-container'>
      <ScoresBlockRow title={'Overall'} score={overallScore} bold={true} />
      {fluencyElement}
      {relevanceElement}
      {vocabularyElement}
      {grammarElement}
      {pronunciationElement}
      {speedElement}

      <ScoreBlockRetryCount retryCount={retryCount} />
    </div>
  );
}

export default ScoresBlockSpeaking;