import ScoreBlockRetryCount from "./scoreBlockRetryCount";
import ScoresBlockRow from "./scoresBlockRow";


function ScoresBlockExpression(props) {

  // Define variables
  const retryCount = props.retryCount;
  const isIelts = props.isIelts
  const scores = props.scores;
  const pronunciationScore = scores.pronunciationScore;
  const ieltsPronunciation = scores.ieltsPronunciation;
  const score = isIelts ? ieltsPronunciation : pronunciationScore;


  return (
    <div class='score-block-container'>
      <ScoresBlockRow title={'Pronunciation'} score={score} bold={true} isIelts={isIelts} />
      <ScoreBlockRetryCount retryCount={retryCount} />
    </div>
  );
}

export default ScoresBlockExpression;