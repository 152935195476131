import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import { translateMessage } from '../utilities/auth/authApi';
import apiCall from '../utilities/auth/apiCall';
import sendAIChatMessage from "../../functions/sendAIChatMessage";

import TranslateToggle from "../utilities/translate/translateToggle";
import Message from "./message";
import NoAccess from "../utilities/noAccess";
import AiTalkResponse from "./aiTalkResponse";
import LoadingBasic from '../utilities/loadingbasic';
import ButtonSubmit from "../utilities/forms/buttonsubmit";
import alertMessage from '../utilities/alertMessage';


function AiTalkThread(props) {

  // Define variables
  const [isWaiting, setIsWaiting] = useState(true);
  const [translateToggle, setTranslateToggle] = useState(false);
  const [translateContent, setTranslateContent] = useState([]);
  const [doneWithMessages, setDoneWithMessages] = useState(false);
  const [doneButtonLoading, setDoneButtonLoading] = useState(false);
  const [phraseCount, setPhraseCount] = useState(0);

  const scrollerRef = useRef(null);
  const firstName = useSelector((state) => state.profile.firstName);

  const id = props.id;
  const learnerHasAccessToLesson = props.learnerHasAccessToLesson;
  const isInitialized = props.isInitialized;
  const exerciseId = props.exerciseId;
  const learnerSpeakingTurns = props.learnerSpeakingTurns;
  const initialDisplayMessage = props.initialDisplayMessage;
  const everyMessageAddonResponderText = props.everyMessageAddonResponderText;
  const firstExampleExtraContext = props.firstExampleExtraContext;
  const avatarUrl = props.avatarUrl;
  const iconUrl = props.iconUrl;
  const messages = props.messages;
  const extras = props.extras;
  const addMessage = props.addMessage;
  const addResponse = props.addResponse;
  const completeLesson = props.completeLesson;
  const logStart = props.logStart;
  const headerElement = props.headerElement;
  const phrases = props.phrases;

  const stepCount = messages.filter(m => m.role === 'user').length;


  // Component load actions
  useEffect(() => {

    scrollContainer();
    sendMessages();

    // Log the lesson start if the learner makes their first response
    if( stepCount === 2 && !exerciseId ) {
      logStart();
    }

  }, [id, messages]);


  // Define functions
  function sendMessages() {

    const lastMessageRole = messages?.length ? messages[messages.length - 1].role : null;

    if( lastMessageRole === 'user' ) {

      const messagesToSend = JSON.parse(JSON.stringify(messages));

      // Clean the attributes b/c it makes the request fail
      messagesToSend.map((message) => {delete message.audioUrl });
      messagesToSend.map((message) => {delete message.responseObject });
      messagesToSend.map((message) => {delete message.display });

      // Append some wording to message to give additional instructions
      const lastMessage = messagesToSend[messagesToSend.length - 1];
      lastMessage.content = lastMessageContent(lastMessage.content);

      const payload = {
        messages: messagesToSend,
        iconUrl: iconUrl,
        firstName: firstName,
        extras: extras
      }

      sendAIChatMessage(payload).then((response) => {
        if( response?.success ) {

          const content = response.content;
          const message = content.message;

          // Look for the audio url
          const messageAudioUrl = response.messageAudioUrl;

          const newMessage = {role: "assistant", content: message, audioUrl: messageAudioUrl};

          addMessage(newMessage);

          // Get the translation
          if( translateToggle ) {
            lookupTranslation(message, messages.length);
          }

          setTimeout(() => {
            scrollContainer();
          }, 200);
        } else {
          alertMessage('Sorry, there was an error. Please reload the lesson or contact support@flowspeak.io for assistance.', 'error');
        }
      });
    } else if( lastMessageRole === 'assistant') {

      setIsWaiting(false);

      // See if the lesson is already done
      if( stepCount === learnerSpeakingTurns ) {
        setDoneWithMessages(true);
      }

    }

  }

  function handleTranscript(transcript, audioBlobUrl, blob, responseObject) {

    // Add the blob
    responseObject.blob = blob;
    responseObject.text = transcript;

    const newMessage = {
      role: "user",
      content: transcript,
      audioUrl: audioBlobUrl,
      responseObject: responseObject
    }

    setIsWaiting(true);
    addMessage(newMessage);
    addResponse(responseObject);
  }

  function completeSegment() {
    setDoneButtonLoading(true);
    completeLesson();
    setTimeout(() => setDoneButtonLoading('finalized'), 1200);
  }

  function handleTranslationToggle(value) {
    setTranslateToggle(value);

    if( value === true ) {
      lookupTranslations();
    }
  }

  function lookupTranslations() {
    messages.map((message, index) => {
      if( message.role === 'assistant' || message.role === 'system' ) {
        const translationExists = translateContent.find(t => t.index === index);
        if( !translationExists ) {
          lookupTranslation(message.content, index);
        }
      }
    });

    // Account for initialDisplayMessage
    if( initialDisplayMessage ) {
      const translationExists = translateContent.find(t => t.index === 'initialDisplayMessage');
      if( !translationExists ) {
        lookupTranslation(initialDisplayMessage.content, 'initialDisplayMessage');
      }
    }
  }

  function lookupTranslation(message, index) {
    apiCall(translateMessage, {message: message}).then((response) => {

      if( response.success ) {
        const translation = response.data.translation;
        setTranslateContent(
          translateContent => [
            ...translateContent,
            {index: index, translation:translation}
          ]
        );

      } else {
        return null;
      }
    }).catch((error) => {
      reportError(error);
    });
  }

  async function generateTips(messageIndex) {
    const trimmedMessages = messages.slice(0, (messageIndex + 1));
    return generateSpecialMessage(trimmedMessages, 'generateTips', extras).then((response) => {
      return response;
    });
  }

  async function generateExample() {

    const messageArray = JSON.parse(JSON.stringify(messages));

    if( firstExampleExtraContext && firstExampleExtraContext.length > 0 && stepCount === 1 ) {
      messageArray.push({role: 'system', content: firstExampleExtraContext});
    }

    // Include the phrases
    let extrasObject = extras;
    if( phrases ) {
      // extrasObject = structuredClone(extras);
      extrasObject = {...extras};

      const choosePhrase = phrases[phraseCount];
      if( choosePhrase ) {
        extrasObject.phrase = choosePhrase.phrase;
        setPhraseCount(phraseCount + 1);
      }
    }

    // return generateSpecialMessage(messageArray, 'generateExample', extras).then((response) => {
    return generateSpecialMessage(messageArray, 'generateExample', extrasObject).then((response) => {
      return response;
    });
  }

  function scrollContainer() {
    setTimeout(() => {
      if( scrollerRef && scrollerRef.current ) {
        scrollerRef.current.scrollTo({
          top: scrollerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 500);
  }

  function lastMessageContent(content) {
    if( stepCount === learnerSpeakingTurns ) {
      // If last message, end the conversation
      content = content + '. Please say something to end the conversation in an upbeat but final way. Use less than 145 characters.'
    } else {
      // Add the additional text to keep the context
      content = content + '. ' + everyMessageAddonResponderText;
    }

    return content;
  }


  // Screen components
  let loadingElement;
  if( !isInitialized ) {
    loadingElement = <LoadingBasic />;
  }

  let waitingElement;
  if( isWaiting ) {
    waitingElement = (
      <Message
        iconUrl={iconUrl}
        message={{role: 'assistant', content: <div class='dot-flashing'></div>}}
      />
    );
  }

  let starterMessageElement;
  if( initialDisplayMessage ) {
    starterMessageElement = (
      <Message
        iconUrl={iconUrl}
        message={initialDisplayMessage}
        translateToggle={translateToggle}
        translation={translateContent.find(t => t.index === 'initialDisplayMessage')}
      />
    );
  }

  let toneElement;
  if( extras?.toneLearner?.includes('professional') ) {
    toneElement = <div class='ai-tone-tip'><strong>Tip</strong> - try speaking with a professional tone <span class='nowrap'>to receive a good score</span></div>;
  }

  let doneButtonElement;
  let responseElement;

  if( doneWithMessages ) {
    // Hide the button if finalized
    // So that people can't find a way to keep clicking it
    if( doneButtonLoading !== 'finalized' ) {
      doneButtonElement = (
        <div class='big-record-outer-wrapper'>
          <div class='flex-column aitalk-done-button-container'>
            <ButtonSubmit
              value={'Done'}
              handleButtonClick={completeSegment}
              loading={doneButtonLoading}
              title={'Tap to complete the lesson'}
            />
          </div>
        </div>
      );
    } else {
      doneButtonElement = (
        <div class='big-record-outer-wrapper'>
          <div class='flex-column aitalk-done-button-container'>
            &nbsp;
          </div>
        </div>
      );
    }
  } else {
    responseElement = (
      <AiTalkResponse
        handleTranscript={handleTranscript}
        isWaiting={isWaiting}
        messages={messages}
        generateExample={generateExample}
        extras={extras}
      />
    );
  }

  let noAccessElement;
  if( !learnerHasAccessToLesson ) {
    noAccessElement = <NoAccess />;
    responseElement = null;
  }


  return (
    <section class='lesson-content' ref={scrollerRef}>

      {headerElement}

      <TranslateToggle
        id={id}
        apiMethod={translateMessage}
        lessonType={'AI Talk'}
        translateToggle={translateToggle}
        setTranslateToggle={setTranslateToggle}
        handleTranslationToggle={handleTranslationToggle}
        translateContent={null}
        setTranslateContent={setTranslateContent}
      />

      <div class='body-content'>

        {loadingElement}

        {toneElement}
        {starterMessageElement}
        {messages.map((message, index) =>
          <Message
            key={`message-${index}`}
            messageIndex={index}
            iconUrl={iconUrl}
            avatarUrl={avatarUrl}
            message={message}
            generateTips={generateTips}
            translateToggle={translateToggle}
            translation={translateContent.find(t => t.index === index)}
          />
        )}

        {waitingElement}
        {responseElement}
        {doneButtonElement}
        {noAccessElement}

      </div>
    </section>
  );
}

export default AiTalkThread;


// --

async function generateSpecialMessage(messages, specialMessageType, extras) {

  let generatedTips = 'Sorry, we could not generate any tips.';
  const messagesToSend = JSON.parse(JSON.stringify(messages));

  // Clean the attributes b/c it makes the request fail
  messagesToSend.map((message) => {delete message.audioUrl });
  messagesToSend.map((message) => {delete message.responseObject });
  messagesToSend.map((message) => {delete message.display });

  const payload = {
    messages: messagesToSend,
    specialMessageType: specialMessageType,
    extras: extras
  }

  return sendAIChatMessage(payload).then((response) => {
    if( response?.success ) {
      const content = response.content.message;
      generatedTips = content;
    } else {
      // Handle
      Mixpanel.track(`Event: Generate ${specialMessageType} Error`, {error: JSON.stringify(response)});
    }

    return generatedTips;

  }).catch((error) => {
    console.log(error);
    reportError(error);
  });

}