import { useState, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";

import { Mixpanel } from './analytics/mixpanelHelpers';
import alertMessage from "./alertMessage";
import { updatePersonalInformation } from "./auth/authApi";
import { languageCodes } from "./languagecodes";
import { countryList } from "./countrylist";
import VectorIcon from "./vectorIcon";
import AvatarUploader from './avatarUploader';

import dashboardSlice from '../../store/dashboard';
import profileSlice from '../../store/profile';


function DashboardProfileSetupModal(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function incrementProfileSetup(field, value) {
    setTimeout(() => {
      dispatch(dashboardSlice.actions.incrementProfileSetup({field, value}));
    }, 1000);
  }

  function updateField(field, value) {
    dispatch(profileSlice.actions.updateField({field, value}));
  }


  // Define variables
  const scrollViewRef = useRef();
  const [countryComplete, setCountryComplete] = useState(false);
  const [nativeComplete, setNativeComplete] = useState(false);
  const [avatarComplete, setAvatarComplete] = useState(false);
  const [avatarImage, setAvatarImage] = useState('');
  const closeModal = props.closeModal;
  const profileSetup = props.profileSetup;
  const askCountry = profileSetup.askCountry;
  const askNativeLanguage = profileSetup.askNativeLanguage;
  const askAvatar = profileSetup.askAvatar;


  // Component load actions
  useEffect(() => {

    if( askCountry != true ) { setCountryComplete(true) }
    if( askNativeLanguage != true ) { setNativeComplete(true) }
    if( askAvatar != true ) {
      setAvatarImage(askAvatar);
      setAvatarComplete(true);
    }

  }, []);


  // Define functions
  function saveData(name, value, setter, field, reduxField) {

    const payload = {
      user: {
        [name]: value
      }
    }

    updatePersonalInformation(payload).then((response) => {
      if( response && response.data ) {
        if( response.data.success ) {
          setter(true);
          incrementProfileSetup(field, value);
          updateField(reduxField, value);
        } else {
          alertMessage(response.data.error);
        }
      } else {
        alertMessage('Sorry, there was a problem', 'error');
      }
    }).finally(() => {
      // changeElement(element, true);
    });

    Mixpanel.track('Event: Profile Setup - save data', {field: field});
  }

  function handleCountryChange(event) {
    const value = event.target.value;
    saveData('country', value, setCountryComplete, 'askCountry', 'country');
  }

  function handleLanguageChange(event) {
    const value = event.target.value;
    saveData('native_language', value, setNativeComplete, 'askNativeLanguage', 'nativeLanguage');
  }

  function handleUploadOwnClick() {
    setTimeout(() => {
      scrollViewRef.current.scrollTo({
        top: scrollViewRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }, 200);
  }

  function afterAvatarUpload(newAvatarUrl) {
    setAvatarImage(newAvatarUrl);
    setAvatarComplete(true);
    incrementProfileSetup('askAvatar', newAvatarUrl);
  }


  // Screen components
  const completeMarkElement = (
    <div class='profile-setup-complete-mark'>
      <VectorIcon icon='checkMarkCircle' color='#BC0181' size={'1.75rem'} />
    </div>
  );

  let countryCompleteElement;
  let countryClass;
  if( countryComplete ) {
    countryClass = 'complete';
    countryCompleteElement = completeMarkElement;
  }

  let nativeCompleteElement;
  let nativeClass;
  if( nativeComplete ) {
    nativeClass = 'complete';
    nativeCompleteElement = completeMarkElement;
  }

  let avatarElement;
  let avatarCompleteElement;
  let avatarClass;
  if( avatarComplete ) {
    avatarClass = 'complete';
    avatarElement = (
      <div style={{marginBottom: '15px'}}>
        <img src={avatarImage} style={{maxWidth: '100px', borderRadius: '100px'}} />
      </div>
    );

    avatarCompleteElement = completeMarkElement;
  } else {

    avatarElement = (
      <AvatarUploader
        handleUploadOwnClick={handleUploadOwnClick}
        afterAvatarUpload={afterAvatarUpload}
      />
    );

  }


  return (
    <div class='window-modal-wrapper'>
      <div class='window-modal profile-setup-modal' ref={scrollViewRef}>

        <div class='window-modal-header'>
          <span class='icon'>&nbsp;</span>

          <div>
            <p>Welcome To</p>
            <h2>FLOW Speak</h2>
          </div>

          <button class='icon icon-close-light' onClick={closeModal}></button>
        </div>

        <div class='window-modal-body'>
          <h3>Build Your Profile 🎉</h3>

          {/* Country */}
          <div class='setup-profile-block'>
            {countryCompleteElement}
            <div class={`flex-column setup-profile-block-inner ${countryClass}`}>
              <p>Show the community a flag <span class='nobr'>of your home country</span></p>
              <div class='profile-input-wrapper'>
                <div class='profile-input'>
                  <select
                    type='select'
                    defaultValue={askCountry}
                    onChange={handleCountryChange}
                  >
                    <option value=''>Select</option>
                    {countryList.map((language, index) =>
                      <option key={index} value={language.value}>{language.name}</option>
                    )};
                  </select>
                </div>
              </div>
            </div>
          </div>


          {/* Native Language */}
          <div class='setup-profile-block'>
            {nativeCompleteElement}
            <div class={`flex-column setup-profile-block-inner ${nativeClass}`}>
              <p>Set your native language <span class='nobr'>to enable translations</span></p>
              <div class='profile-input-wrapper'>
                <div class='profile-input'>
                  <select
                    type='select'
                    defaultValue={askNativeLanguage}
                    onChange={handleLanguageChange}
                  >
                    <option value=''>Select</option>
                    {languageCodes.map((language, index) =>
                      <option key={index} value={language.value}>{language.name}</option>
                    )};
                  </select>
                </div>
              </div>
            </div>
          </div>


          {/* Avatar */}
          <div class='setup-profile-block'>
            {avatarCompleteElement}
            <div class={`flex-column setup-profile-block-inner ${avatarClass}`}>
              <p>Add your profile picture <span class='nobr'>to brighten things up</span></p>
              {avatarElement}
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default DashboardProfileSetupModal;